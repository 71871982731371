.wrapper {
    color: black;
    position: fixed;
    background: rgba(255, 255, 255, 0.75);
    z-index: 1005;

    display: flex;
    justify-content: center;
    align-items: center;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.inner {
    margin: auto;
    z-index: 1006;
    max-height: calc(100vh - 4rem);
    max-width: calc(100% - 4rem);
    overflow: auto;

    position: fixed;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0 0 20px, rgba(0, 0, 0, 0.25) 0 0 2px;
}

.enter {
    opacity: 0;

    .inner {
        transform: scale(0.9);
    }
}

.enterActive,
.enterDone {
    opacity: 1;

    .inner {
        transform: scale(1);
    }
}

.enterActive,
.exitActive {
    transition: opacity 350ms;

    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }

    .inner {
        transition: transform 350ms;

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }
}

.exit {
    opacity: 1;

    .inner {
        transform: translateY(0);
    }
}

.exitActive,
.exitDone {
    opacity: 0;

    .inner {
        transform: translateY(20px);
    }
}

.cols {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.5em;
    justify-content: space-between;
}

.col {
    width: 33%;
    min-width: 15rem;
    margin-bottom: 2rem;

    h4 {
        margin: 0;
        margin-left: 0.2em;
    }

    li {
        margin-top: 0.3em;
        display: flex;
        align-items: center;
        line-height: 1.2rem;
    }
}

.shortcuts {
    display: inline-block;
    padding: 0 0.5em;
}

.keys {
    text-align: right;
    display: inline-block;
    margin-right: 0.5em;
    width: 5em;
    float: left;
    white-space: nowrap;
    flex-shrink: 0;
}

.key {
    font-family: monospace;
    display: inline-block;
    background: #444;
    color: white;
    border-radius: 3px;
    margin-right: 0.2em;
    box-sizing: border-box;
    padding: 0 0.2rem;
    min-width: 1.9rem;
    height: @min-width;
    line-height: 1.4rem;
    text-align: center;
    font-size: 1.2em;
    text-shadow: 0 1px 0 #111;

    border: 3px solid #666;
    border-bottom-color: #222;
    border-top-color: #888;
}

.close {
    height: 2em;
    position: absolute;
    top: 2px;
    right: 0.7em;
    cursor: pointer;

    svg {
        color: black;
        height: 1em;
    }
}

h1 {
    font-size: 15px;
}

h2 {
    font-size: 14px;
}
