.button,
a.button {
    font-family: inherit;
    display: inline-block;
    padding: 0 1em;
    margin: 0;
    border-radius: 3px;
    font-size: 14px;
    font-weight: normal;
    line-height: 2em;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #f2f2f2;
    background-image: linear-gradient(#f2f2f2, #eaeaea);
    color: #333;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.05), inset -1px 0 0 rgba(255, 255, 255, 0.025),
        inset 1px 0 0 rgba(255, 255, 255, 0.025), 0 1px 1px rgba(0, 0, 0, 0.1);
    border: 1px solid transparent;
    text-decoration: none;

    & svg {
        height: 12px;
        width: @height;
        margin-right: 5px;
        display: inline;
        color: inherit !important;
    }

    &:visited {
        color: #333;
    }

    &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
    }

    &:hover,
    &:focus,
    &:visited:hover,
    &:visited:focus {
        background-image: linear-gradient(white, #fafafa);
        border-color: #d9d9d9;
        border-top-color: lightgray;
        border-bottom-color: #ccc;
        text-decoration: none;
        color: #333;
    }

    &.disabled {
        pointer-events: none;

        &,
        &:hover,
        &:focus,
        &:visited:hover,
        &:visited:focus {
            cursor: default;
            background-image: none;
            color: #999;
            box-shadow: none;
            text-shadow: none;
        }
    }

    &.blue {
        color: white;
        border-color: #254e74;
        border-top-color: #27547c;
        border-bottom-color: #214769;
        background-color: #306697;
        background-image: linear-gradient(#306697, #2c5e8b);

        &:visited {
            color: white;
        }

        &:hover,
        &:focus,
        &:visited:hover,
        &:visited:focus {
            color: white;
            background: #306697;
            border-color: #254e74;
        }

        &.disabled {
            &,
            &:hover,
            &:focus,
            &:visited:hover,
            &:visited:focus {
                color: rgba(255, 255, 255, 0.6);
                background: #306697;
                border-color: #254e74;
            }
        }
    }

    &.grey {
        color: #333;
        border-color: #dbdbdb;
        border-top-color: #e0e0e0;
        border-bottom-color: lightgray;
        background-color: #f2f2f2;
        background-image: linear-gradient(#f2f2f2, #eaeaea);

        &:visited {
            color: #333;
        }

        &:hover,
        &:focus,
        &:visited:hover,
        &:visited:focus {
            background: #f6f6f6;
            color: #333;
        }

        &.disabled {
            color: #999;
        }
    }

    &.green {
        color: white;
        border-color: #005207;
        border-top-color: #005c08;
        border-bottom-color: #004306;
        background-color: #00800b;
        background-image: linear-gradient(#00800b, #00710a);

        &:visited {
            color: white;
        }

        &:hover,
        &:focus,
        &:visited:hover,
        &:visited:focus {
            background: #00800b;
            color: white;
            border-color: #005207;
        }

        &.disabled {
            &,
            &:hover,
            &:focus,
            &:visited:hover,
            &:visited:focus {
                color: rgba(255, 255, 255, 0.6);
                border-color: #005207;
                background: #00800b;
            }
        }
    }

    &.red {
        color: white;

        border-color: #9c2f2e;
        border-top-color: #a33230;
        border-bottom-color: #902c2a;
        background-color: #bf3a38;
        background-image: linear-gradient(#bf3a38, #b33635);

        &:hover,
        &:focus {
            color: white;
            border-color: #982e2c;
            border-top-color: #902c2a;
            border-bottom-color: #842827;
            background-image: linear-gradient(#ca4d4b, #c7413f);
        }

        &:active {
            border-color: #702221;
            background-color: #c74b4a;
            background-image: linear-gradient(#bf3a38, #c94947);
        }

        &.disabled {
            color: rgba(255, 255, 255, 0.6);
        }
    }

    &.nav {
        height: 60px;
        padding: 0 16px;
        background: none;
        border: none;
        font-size: 16px;
        color: #f2f2f2;
        cursor: pointer;
        text-shadow: rgba(0, 0, 0, 0.25) 0 -1px 1px;
        text-decoration: none;
        display: inline-block;
        line-height: 60px;
        margin: 0;
        z-index: 1;

        &:hover {
            color: white;
            background: black;

            .caret {
                color: inherit;
                forced-color-adjust: auto;
            }
        }

        &:focus {
            color: white;
            background: black;
            /* stylelint-disable no-descending-specificity */
            .caret {
                color: inherit;
                forced-color-adjust: auto;
            }
        }
    }

    &.link {
        background: none;
        box-shadow: none;
        border: none;
        color: #2653d9;
        cursor: pointer;
        padding: 0;
        margin: 0;

        &:visited {
            color: #2653d9;
        }

        &:hover,
        &:focus,
        &:visited:hover,
        &:visited:focus {
            color: #07b;
            text-decoration: underline;
            background: none;
        }

        &.grey {
            color: #666;

            &:visited {
                color: #666;
            }

            &:hover,
            &:focus,
            &:visited:focus,
            &:visited:hover {
                color: #07b;
            }
        }
    }

    &.small {
        font-size: 0.857em;
        padding-left: 0.5em;
        padding-right: 0.5em;

        & svg {
            height: 0.6rem;
            width: @height;
            display: inline;
        }
    }
    &.black {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        height: 44px;
        background: #31312f;
        border: 1px solid #31312f;
        color: #f5f4ec;
        border-radius: 4px;
        flex: none;
        order: 0;
        flex-grow: 1;
    }
}
