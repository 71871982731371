.bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;

    background: #c004;
    border-top: 1px solid #c00;
    z-index: 999999;
}
