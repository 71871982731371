.skittle {
    border-radius: 1.6em;
    border: 1px solid transparent;
    color: white;
    cursor: default;
    display: inline-block;
    font-size: 0.85em;
    font-weight: bold;
    height: 0.9rem;
    line-height: 0.9rem;
    min-width: 1.6em;
    padding: 0 0.4em;
    position: relative;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.5) 0 0 1px;

    .wrap {
        align-items: center;
        display: flex;
    }

    .icon {
        display: inline-block;
        height: 0.75rem;
        margin-right: 0.25rem;
        width: 0.75rem;
    }
    .countTwoDigits {
        svg {
            width: 10px;
        }
    }
}

.tooltip {
    visibility: hidden;

    position: absolute;
    background: black;
    padding: 2px 0.5em;
    white-space: nowrap;
    font-weight: normal;

    box-sizing: border-box;
    text-align: center;

    top: -1.4em;
    left: 50%;
    transform: translate(-50%, -50%);

    &::after {
        content: '';
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-top-color: black;
        position: absolute;
        top: 1.5em;
        left: 0;
        right: 0;
        margin: auto;
    }

    .skittle:hover &,
    .skittle:focus & {
        visibility: visible;
    }
}

.collection {
    background: #00800b;
}

.wantlist {
    background: #bf3a38;
}

.inventory {
    background: #306697;

    .icon {
        height: 0.65rem;
        width: 0.65rem;
    }
}
