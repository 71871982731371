$mobile: 600px;

.user {
    position: relative;
    font-size: 0;
    display: inline-block;
    color: white;
    z-index: 100;

    button {
        height: 60px;
        border: none;
        background: none;
        cursor: pointer;
        padding: 0 7px 0 10px;
        color: inherit;
        outline: none;

        &:focus-visible {
            /* stylelint-disable declaration-block-no-duplicate-properties */
            outline: Highlight auto 1px;
            outline: -webkit-focus-ring-color auto 1px;
        }
    }

    &.open {
        background: black;
    }

    &.open,
    button:hover,
    button:focus {
        color: white;
        background: black;
    }
}

.bezel {
    display: inline-block;
    position: relative;
    background: #444;

    width: 28px;
    height: 28px;
    vertical-align: middle;

    &::after {
        content: '';
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-top-color: rgba(255, 255, 255, 0.25);
        border-bottom-color: rgba(255, 255, 255, 0.15);
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.loading {
    composes: user;
    width: 45px;
    height: 60px;
    line-height: 60px;
    color: #666;
    pointer-events: none;
    padding: 0 7px 0 10px;

    .bezel {
        opacity: 0.5;
    }
}

.pic {
    width: 28px;
    height: 28px;
}

.caret {
    height: 14px;
    vertical-align: middle;
    margin-left: 8px;
}

.dropdown {
    position: absolute;
    right: 0;

    list-style: none;
    background: black;
    box-shadow: rgba(0, 0, 0, 0.25) 0 0 20px, rgba(0, 0, 0, 0.25) 0 0 2px;

    width: 150px;
    font-size: 16px;
    text-align: initial;
    padding: 8px;

    a {
        color: #f2f2f2;
        padding: 0.4rem 10px;
        display: block;

        &:visited {
            color: #f2f2f2;
        }

        &:hover,
        &:focus,
        &:visited:hover,
        &:visited:focus {
            color: #386aff;
        }
    }

    &[hidden] {
        visibility: none;
    }

    @media screen and (max-width: $mobile) {
        width: 100vw;
        height: 100vh;
        padding: 8px 0;
        right: -8px;
    }
}

.icon {
    width: 15px;
    margin-right: 0.6em;
    margin-top: 1px;
}

.spacer {
    border: none;
    border-top: 1px solid #333;
}

.loggedout {
    font-size: 15px;
    height: 60px;
    line-height: @height;

    /* stylelint-disable no-descending-specificity */
    a {
        display: inline-block;
        color: #f2f2f2;

        &:visited {
            color: #f2f2f2;
        }

        &:hover,
        &:focus,
        &:visited:hover,
        &:visited:focus {
            color: white;
            text-decoration: none;
        }
    }
}

a.register {
    background-color: #f5df2e;
    height: 2em;
    line-height: 2em;
    padding: 0 10px;
    border-radius: 2px;
    margin-left: 10px;
    color: black;

    &:visited {
        color: black;
    }

    box-shadow: rgba(255, 255, 255, 0.047) 0 1px 0 0 inset, rgba(255, 255, 255, 0.024) -1px 0 0 0 inset,
        rgba(255, 255, 255, 0.024) 1px 0 0 0 inset, rgba(0, 0, 0, 0.098) 0 1px 1px 0;

    &:focus {
        background: #668cff;
        border: 1px solid #333;
        outline: 1px solid #668cff;
    }
    &:hover {
        background-color: #f7e65e;
        color: black;
        text-decoration: none;
    }

    &:active {
        background-color: #f7e65e;
        box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.15), 0 1px 1px rgba(0, 0, 0, 0.2);
    }
}

.tooltip {
    visibility: hidden;
    font-size: 11px;
    background: black;
    position: absolute;
    right: 71px;
    width: auto;
    white-space: nowrap;
    height: 12px;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 3px 8px;
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
    text-align: center;

    /* stylelint-disable-next-line a11y/selector-pseudo-class-focus */
    button:hover + & {
        visibility: visible;
    }

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;

        top: 50%;
        right: -5px;
        margin-top: -5px;
        border-left-color: black;
        border-width: 5px 0 5px 5px;
    }
}
