.section {
    margin: 0;
    margin-bottom: 1em;

    &.mobile {
        margin-bottom: 0;
    }
}

.header {
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    background: white;
    z-index: 99;
    user-select: none;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        margin-left: -5px;
        padding: 5px;
        flex-shrink: 1;
        width: 100%;
    }

    .collapsible & {
        padding-right: 1.6rem;
    }

    .mobile & {
        min-height: 44px;
    }

    .mobile.collapsible & {
        position: sticky;
        top: 0;
    }
}

.toggle {
    position: absolute;
    right: 0.5em;
    font: inherit;
    top: 0;
    bottom: 0;
    margin: auto;

    background: none;
    border: none;
    padding: 0;

    cursor: pointer;

    height: 1em;
    width: 1em;
    color: gray;
    transition: transform 300ms;

    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }

    &:focus,
    &:hover {
        color: black;
    }

    .open & {
        transform: scaleY(-1);
    }
}

.content {
    display: block;
    margin-top: 5px;

    .mobile & {
        padding-bottom: 1em;
    }

    /* stylelint-disable-next-line a11y/no-display-none */
    &[hidden] {
        display: none;
    }
}
