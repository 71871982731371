.root {
    font-family: 'Helvetica Neue', 'Helvetica', 'Nimbus Sans', 'Arial', sans-serif;
    font-size: 14px;
}

body {
    margin: 0;
    padding: 0;
}

a {
    color: #2653d9;
    text-decoration: none;

    &:visited {
        color: #98158b;
    }

    &:focus,
    &:hover,
    &:visited:hover,
    &:visited:focus {
        color: #07b;
        text-decoration: underline;
    }
}

:global(.icon.icon-user::before) {
    display: inline-block;
    width: 1em;
    height: 1em;
    content: '';
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 805 1024" width="805" height="1024"><path fill="black" d="M805 803q0 68-42 108t-111 40H153q-70 0-111-40T0 803q0-30 2-59t8-63 15-62 25-55 35-47 49-30 64-12q5 0 24 13t42 27 62 28 76 12 77-12 61-28 43-27 24-13q35 0 64 12t48 30 36 47 24 55 16 62 8 63 2 59zM622 293q0 90-65 155t-155 64-155-64-64-155 64-156 155-64 155 64 65 156z" /></svg>');
    background-size: 0.8em;
    background-position: center bottom;
    background-repeat: no-repeat;
    position: relative;
    top: 2px;
    left: 1px;
}
