$mobile: 600px;

.headericon,
a.headericon {
    display: flex;
    height: 60px;
    line-height: 68px;
    padding: 0 18px;

    background: none;
    border: none;
    font-size: inherit;
    outline: none;

    position: relative;
    color: inherit;
    cursor: pointer;
    align-items: center;

    &:visited {
        color: inherit;
    }

    &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
    }

    &:focus,
    &:hover,
    &:visited:focus,
    &:visited:hover {
        color: inherit;
        text-decoration: none;
        background: black;
    }

    @media screen and (max-width: $mobile) {
        line-height: 70px;
        padding: 0 8px;
    }
}

.headericon.empty {
    color: inherit;

    &:visited {
        color: inherit;
    }

    &:hover,
    &:focus,
    &:visited:hover,
    &:visited:focus {
        color: inherit;
    }
}

.icon {
    width: 20px;
    height: 20px;
    padding-right: 4px;

    @media screen and (max-width: $mobile) {
        width: 20px;
        height: @width;
    }
}

.tooltip {
    &[hidden] {
        visibility: hidden;
    }

    font-size: 11px;
    background: black;
    color: white;

    position: absolute;
    top: calc(60px + 5px);
    margin: auto;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);

    width: auto;
    height: 18px;
    line-height: @height;
    padding: 0 8px;

    white-space: nowrap;
    pointer-events: none;

    &::before {
        content: '';
        width: 0;
        height: 0;

        position: absolute;
        top: -5px;
        left: 50%;

        margin-left: -5px;

        border-width: 0 5px 5px;
        border-color: transparent;
        border-style: solid;
        border-bottom-color: black;
    }
}
