$mobile: 700px;

.content {
    max-width: calc(1288px + 4em);
    width: 100%;
    box-sizing: border-box;

    margin: 0 auto;
    padding: 0 2em;

    @media (max-width: 1100px) {
        padding: 0 8px;
    }

    @media (max-width: $mobile) {
        display: flex;
        flex-direction: column;
    }
}

.contentNoMaxWidth {
    composes: content;
    max-width: 1600px;
}

.main {
    width: calc(70% - 2em);
    box-sizing: border-box;
    float: left;

    @media (min-width: $mobile) {
        order: 0 !important;
    }

    @media (max-width: $mobile) {
        float: none;
        clear: none;
        width: 100%;
    }
}

.full {
    width: 100%;
    box-sizing: border-box;
    float: left;

    @media (min-width: $mobile) {
        order: 0 !important;
    }

    @media (max-width: $mobile) {
        float: none;
        clear: none;
        width: 100%;
    }
}

.side {
    width: 30%;
    box-sizing: border-box;
    margin-left: 70%;

    @media (min-width: $mobile) {
        order: 0 !important;
    }

    @media (max-width: $mobile) {
        width: 100%;
        margin: 0;
    }
}

.nomobile {
    @media (max-width: $mobile) {
        display: none;
    }
}

.clear {
    clear: both;
}
