$collapse: 650px;
$collapse-bottom: 1000px;

.footer {
    background: #232323;
    border-top: 1px solid #e5e5e5;
    line-height: 2em;
    color: #ccc;

    a {
        color: white;

        &:visited {
            color: white;
        }

        svg {
            height: 1em;
            width: 1em;
        }
    }

    a .logo {
        height: 2em;
        width: auto;
        margin-right: 1em;
        vertical-align: middle;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    button {
        padding: 0;
        color: white;
        font: inherit;
        background: none;
        border: none;
        outline: none;
        text-align: inherit;
    }

    header {
        margin: 0;
        margin-bottom: 0.5em;
        font-size: 1.15em;
    }

    a:focus,
    a:hover,
    button:focus,
    button:hover {
        color: #4dd2ff;
        text-decoration: underline;
    }
}

.columns {
    @media (min-width: $collapse) {
        display: flex;
        justify-content: space-between;
    }
}

.footer .section {
    width: 100%;
    box-sizing: border-box;
    flex-shrink: 1;
    margin-top: 1em;
    padding-right: 1em;
    text-align: center;

    /* stylelint-disable-next-line a11y/no-display-none */
    .content {
        display: none;

        @media (min-width: $collapse) {
            display: block;
        }

        @media (max-width: $collapse-bottom) {
            li {
                padding: 4px 0;
                line-height: 1.6em;
            }
        }
    }

    header {
        font-weight: bold;
        button {
            color: #ccc;

            &:focus,
            &:hover {
                color: #ccc;
                text-decoration: none;
                cursor: pointer;
            }

            svg {
                margin-left: 1em;
                width: 0.8em;
                color: #ccc;
            }

            &:disabled {
                cursor: default;
            }
            /* stylelint-disable declaration-block-no-duplicate-properties */
            &:focus-visible {
                outline: Highlight auto 1px;
                outline: -webkit-focus-ring-color auto 1px;
            }
        }
    }

    &:last-child {
        padding-right: 0;
    }

    &.open {
        .content {
            display: block;
        }

        header button svg {
            transform: scaleY(-1);
        }
    }

    @media (min-width: $collapse) {
        text-align: left;

        header {
            button {
                cursor: default;

                /* stylelint-disable-next-line a11y/no-display-none */
                svg {
                    display: none;
                }
            }
        }
    }
}

.bottom {
    width: 100%;
    flex-shrink: 0;
    background: #333;
    padding: 1rem 0;
    font-size: 0.85em;
    margin-top: 2em;

    @media screen and (min-width: $collapse-bottom) {
        li {
            display: inline-block;
            margin-right: 2em;
            vertical-align: middle;
        }
    }

    .columns {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: $collapse-bottom) {
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

.inner {
    text-align: center;

    ul {
        margin-top: 1em;
    }

    @media screen and (min-width: $collapse-bottom) {
        display: flex;
        text-align: inherit;
        justify-content: space-between;
        align-items: center;

        ul {
            display: inline-block;
            margin-top: 0;
        }
    }
}

button.cookies {
    border: 1px solid white !important;
    color: white !important;
    font-size: 1em !important;
    margin-right: 0;
    padding: 0.4em 0.75em !important;
    line-height: 1em;
    vertical-align: baseline;

    @media screen and (min-width: $collapse-bottom) {
        margin-left: 1em;
    }

    &:focus,
    &:hover {
        background: white !important;
        color: black !important;
        text-decoration: none !important;
        cursor: pointer !important;
    }
}

.footer .i18n {
    display: inline-block;
    color: white;
    cursor: pointer;
    white-space: nowrap;
    vertical-align: middle;
    position: relative;

    select {
        position: absolute;
        left: 0;
        width: 100%;
        opacity: 0;
        cursor: pointer;
    }
}

.globe,
.caret {
    height: 1em;
    vertical-align: middle;
    margin-bottom: 0.1em;
}

.globe {
    margin-right: 0.2em;
}

.caret {
    margin-left: 0.5em;
}

.social {
    width: 100%;
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
    /* stylelint-disable no-descending-specificity */
    svg {
        font-size: 2em;
    }

    @media (max-width: calc($collapse - 1px)) {
        header {
            height: 1rem;
            left: -10000px;
            overflow: hidden;
            width: 1px;
        }

        ul {
            display: flex;
            justify-content: space-around;
        }

        a {
            .srOnly {
                position: absolute;
                left: -10000px;
                top: auto;
                width: 1px;
                height: 1px;
                overflow: hidden;
            }

            svg {
                font-size: 1.5rem;
                display: inline-block;
            }
        }
    }
}

.apps {
    display: flex;
    flex-direction: column;
    align-items: normal;

    img {
        height: 44px;
        width: 144px;
    }

    @media (max-width: 420px) {
        display: flex;
        flex-direction: column;
        align-items: center;

        header {
            height: 0;
            left: -10000px;
            overflow: hidden;
            width: 1px;
        }
    }

    @media (min-width: 421px) and (max-width: 830px) {
        display: flex;
        flex-direction: row;
        justify-content: center;

        header {
            height: 0;
            left: -10000px;
            overflow: hidden;
            width: 1px;
        }
    }
}
