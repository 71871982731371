.banner {
    justify-content: space-between;
    padding: 1em 0;
    background-color: #c5def7;
    border-bottom: 1px solid #9fbfdf;
    color: #124a81;
    position: relative;
    margin-top: -1.75rem;
    margin-bottom: 1.75rem;

    a,
    a:visited {
        color: #0c335a;
        text-decoration: underline;
    }

    &.error {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        position: sticky;
        top: 0;
        z-index: 99;
    }
}

.dismiss {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1em;
    margin: auto;
    color: inherit;

    svg {
        height: 1.4em;
    }
}

.content {
    display: flex;
    align-items: center;
    flex-direction: row;

    .dismissable & {
        padding-right: 3em;
    }
}

.icon {
    height: 1em;
    width: 1em;
    margin-right: 0.75em;
}
