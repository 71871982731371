$mobile: 600px;
$height: 60px;

.header {
    height: $height;
    background: #333;
    border-bottom: 1px solid transparent;

    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    white-space: nowrap;
    z-index: 101;
    box-shadow: inset 0 -1px 0 #262626;

    @media screen and (max-width: $mobile) {
        height: calc($height + $height);
    }
}

.logo {
    width: 110px;
    margin-right: 3em;
    vertical-align: middle;
    flex-shrink: 0;
    margin-top: 6px;
    min-height: 2em;
    color: white;

    svg {
        color: inherit;
        forced-color-adjust: auto;
    }

    &:hover,
    &:focus,
    &:visited,
    &:visited:hover,
    &:visited:focus {
        color: white;
    }

    @media screen and (max-width: 1000px) {
        margin-right: 1em;
    }

    @media (max-width: $mobile) {
        width: 20%;
        max-width: 112px;
        flex-shrink: 10;
    }
}

.search {
    margin-right: 0.5em;
    vertical-align: middle;

    @media (max-width: $mobile) {
        margin-right: 0;
    }
}

.wrapper {
    display: flex;
    height: $height;

    align-items: center;

    @media screen and (max-width: $mobile) {
        height: calc($height + $height);
        width: 100%;
        flex-wrap: wrap;
    }
}

.placeholder {
    height: $height;

    @media screen and (max-width: $mobile) {
        height: calc(2 * $height);
    }
}

.profile {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    color: white;

    .icons {
        display: flex;
        margin: 0;

        li {
            list-style: none;
        }
    }

    @media (max-width: $mobile) {
        flex-grow: 1;
        margin-right: 0;
    }
}
