$transition-duration: 200ms;
$transition-timing: ease-in;
$mobile: 600px;

.search {
    position: relative;
    background: white;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    display: inline-block;
    font-size: 16px;

    width: 100%;
    max-width: 320px;
    flex-grow: 1;

    &.focus,
    &:focus,
    &:hover {
        background: white;
    }

    &.focus {
        max-width: 420px;
        border: 2px solid #386aff;
        border-radius: 1px;
    }

    @media (max-width: $mobile) {
        order: 2;
        width: calc(100% - 4.5em) !important;
        max-width: none !important;
    }

    /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
    transition: background $transition-duration $transition-timing, max-width $transition-duration $transition-timing;

    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }

    input {
        background: none;
        border: none;
        font: inherit;
        appearance: none;
        outline: none;

        height: 44px;
        line-height: 22px;
        padding: 0 0 0 16px;
        width: calc(100% - 4.5em);

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }

        &::placeholder {
            color: #757575;
            text-overflow: ellipsis;
        }
    }
}

.submit {
    background: none;
    border: 1px solid transparent;
    cursor: pointer;
    vertical-align: middle;
    width: 30px;
    height: 28px;
    padding: 1px 7px 2px;
    margin: 0;

    color: black;
}

.spinner,
.icon {
    width: 20px;
    height: 20px;
    margin-bottom: 4px;
    vertical-align: middle;
}

.spinner {
    opacity: 0;
    margin-bottom: 3px;
    animation: spin 1s infinite steps(8, start);

    @media (prefers-reduced-motion: reduce) {
        animation: none;
    }

    .working & {
        opacity: 1;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.dropdown {
    position: absolute;
    width: 99%;
    top: 44px;

    background: white;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);

    /* Delay showing the dropdown until the input has fully completed the width animation */
    transition: opacity 0 $transition-duration;

    &[hidden] {
        transition: none;
        opacity: 0;
        display: block;
        pointer-events: none;
        visibility: hidden;
    }
}

.advanced {
    text-decoration: none;
    display: block;
    line-height: 20px;
    padding: 3px 4px;
    border-top: 1px solid #eee;

    &:visited {
        color: blue;
    }

    &:focus,
    &:hover {
        background: #eee;
    }

    svg {
        width: 13px;
        height: @width;
        position: relative;
        top: 1px;
    }
}

.categories {
    border: 1px solid #e5e5e5;

    button {
        background: #f1f1f1;
        border: none;
        border-right: 1px solid #e5e5e5;
        font: inherit;
        margin: 0;
        width: 25%;
        padding: 7px 0;
        cursor: pointer;
        height: 34px;

        &:last-child {
            border-right: none;
        }
    }
}

.categories button.active {
    background: #326fb2;
    color: white;
}

.results {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        border-bottom: 1px solid #f2f2f2;

        &:last-child {
            border-bottom: none;
        }
    }

    a {
        display: block;
        text-decoration: none;
        font-size: 0;
        outline: none;
    }
}

a.active {
    background: #eee;
    outline: 1px solid #2653d9;
}

.thumbnail {
    width: 50px;
    display: inline-block;
    background-color: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom-color: rgba(0, 0, 0, 0.15);
    border-top-color: rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 1px;
}

.info {
    display: inline-block;
    vertical-align: top;
    padding-left: 7px;
    font-size: 1rem;
    line-height: 16px;
    width: calc(100% - 52px);
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
}

.title {
    font-size: 13px;
    font-weight: bold;
    overflow: hidden;
}

.type {
    display: block;
    color: #333;
    font-size: 12px;
}

.more {
    font-size: 12px;
    color: #666;
    font-weight: normal;
    margin-left: 0.2em;

    a:focus,
    a:hover & {
        color: #333;
    }
}

.marketplace {
    font-size: 11px;
    color: #666;
    display: block;
}

.status {
    /* Taken from https://a11yproject.com/posts/how-to-hide-content/ */
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    white-space: nowrap;

    /* IE6, IE7 */
    clip: rect(1px 1px 1px 1px);

    /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
    clip: rect(1px, 1px, 1px, 1px);
}

.after {
    opacity: 1;
    transition: opacity 0;
    transition-delay: $transition-duration;
    display: flex;
    overflow: show;

    flex-grow: 1;
    justify-content: flex-end;

    @media (min-width: 1051px) {
        justify-content: flex-start;
        &.focus {
            opacity: 0;
            transition-delay: 0s;
            pointer-events: none;
            min-width: 0;
        }
    }

    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }

    @media (max-width: $mobile) {
        order: 3;
    }
}

.skittles {
    float: right;
    font-size: 0.75em;
    padding: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    * {
        margin-bottom: 2px;
    }
}
