$collapse: 1050px;
$mobile: 600px;
$height: 60px;

.wrapper {
    display: flex;
    color: white;
}

.dropdown {
    display: inline-block;
    position: relative;
}

.wrapper .dropdown .button {
    border-radius: 0;
    box-shadow: none;
}

.button .caret {
    height: 14px;
    margin-left: 8px;
    color: inherit;
    position: relative;
    top: 2px;
    forced-color-adjust: auto;
}

.button .caret.active {
    transform: rotate(180deg);
}

.wrapper .dropdown.active .button {
    background: black;
}

/* stylelint-disable-next-line a11y/no-display-none */
.groups {
    color: white;
    background: black;
    display: none;
    border: 1px solid transparent;
    z-index: 2;
    padding-bottom: 25px;
    padding-left: 10px;

    &.active {
        display: flex;
    }

    top: 60px;
    position: absolute;

    @media screen and (min-width: $collapse) {
        left: 0;
        margin-left: -470px;
    }

    .align & {
        margin-left: 0;
    }

    h2 {
        font-size: 16px;
        padding: 0 10px;
        margin-top: 32px;
        margin-bottom: 1rem;
        color: white;
    }

    a,
    a:visited {
        color: #f2f2f2;
    }

    a:hover,
    a:focus {
        color: #386aff !important;
        text-decoration: underline;
    }
}

.group {
    line-height: 20px;
    font-size: 16px;
    padding: 0;

    flex-grow: 1;
    min-width: 190px;

    list-style: none;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        /* stylelint-disable-next-line */
        a {
            padding: 8px 10px;
            text-decoration: none;
            position: relative;
            width: 100%;
            display: block;
            box-sizing: border-box;
        }
    }
}

/* stylelint-disable-next-line a11y/no-display-none */
.mobile {
    display: none;

    @media (max-width: $collapse) {
        display: inline-block;
    }
}

.categories {
    list-style: none;
    margin: 0;
    padding-left: 0;

    .categoriesItem {
        display: inline-block;
    }

    @media (max-width: $collapse) {
        display: flex;
        flex-direction: column;
    }
}

.menu {
    @media (max-width: $collapse) {
        display: none;
        position: absolute;
        top: $height;
        background: black;

        right: 3em;
        width: calc($mobile - 4em);

        &.open {
            display: block;
        }

        .button {
            font-weight: bold;
            width: 100%;
            border-bottom: 1px solid #444;
            background: black;
            text-align: left;

            &.active svg {
                transform: scale(-1);
            }
        }

        .dropdown {
            display: block;
        }

        .groups {
            position: static;
            width: 100%;
            box-sizing: border-box;
            border-bottom: 1px solid #444;

            flex-wrap: wrap;

            ul li {
                line-height: 24px;
            }
        }
    }

    @media (max-width: $mobile) {
        left: 0;
        right: 0;
        width: auto;
        top: calc(2 * $height);
        height: calc(100vh - 2 * $height);
        overflow: auto;
    }
}
